import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import resources from './resources.json'

// i18n.on('languageChanged', function (lng) {
//   
//   const fallBack = i18n.options.fallbackLng as string[];
//   if (lng === fallBack[0]) {
//     if (window.location.pathname.includes('/' + fallBack[0])) {
//       const newUrl = window.location.pathname.replace('/' + fallBack[0], '')
//       window.location.replace(newUrl)
//     }
//   }
// })

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    keySeparator: ".", // we do not use keys in form messages.welcome
    supportedLngs: ["en", "sv"],
    fallbackLng: ['sv'],
    debug: false,
    detection: {
      order: ['path'],
      lookupFromPathIndex: 0
    },
    lowerCaseLng: true,
    cache: []    
  });
export default i18n;